import { IRequest, IRequestSuggestionItem } from '@/interfaces/models/request';
import { computed, ref } from 'vue';
import RequestAPI from '@/api/request.api';
import useMessage from '@/composables/useMessage';
import useContext from '@/composables/useContext';
import router from '@/router';
import { IFile } from '@/interfaces/common';
import { IOffer } from '@/interfaces/models/order';

const context = useContext();
const { requestError, success } = useMessage();

export default function () {
  const loading = ref<boolean>(false);
  const orderLoading = ref<boolean>(false);
  const items = ref<IRequestSuggestionItem[]>([]);
  const form = ref<IRequest>({} as IRequest);
  const selectedFiles = ref<(IFile | File)[]>([]);
  const files = ref<File[]>([]);
  const selectedItems = ref<IRequestSuggestionItem[]>([]);
  const min = ref(0);
  const max = ref(0);

  const parsedSelectedItems = computed<IRequestSuggestionItem[]>(() => {
    if (!selectedItems.value.length) return [];
    let covered = 0;

    return selectedItems.value.map((item) => {
      if (covered + +item.area > form.value.area) {
        const area = form.value.area - covered;
        const totalCost =
          (item.applicationCost / item.area) * area + item.fareCost;

        return {
          ...item,
          area,
          totalCost,
        };
      }

      covered += +item.area;
      return item;
    });
  });
  const selectedSqaure = computed<number>(() =>
    parsedSelectedItems.value.reduce((a, c) => a + c.area, 0)
  );
  const selectedPrice = computed<number>(() =>
    parsedSelectedItems.value.reduce((a, c) => a + c.totalCost, 0)
  );

  function getSearchToken() {
    if (context.state.user) {
      return sessionStorage[context.state.user.uid];
    }
  }

  function setSearchToken(id: string) {
    if (context.state.user) {
      sessionStorage[context.state.user.uid] = id;
    }
  }

  function clearSearchToken() {
    if (context.state.user) {
      sessionStorage.removeItem(context.state.user.uid);
    }
  }

  function mapItems({ squarePerDay, ...rest }: any) {
    return {
      ...rest,
      squarePerDay:
        squarePerDay > form.value.area ? form.value.area : squarePerDay,
    };
  }

  function search(values: IRequest) {
    items.value = [];
    form.value = values;
    selectedItems.value = [];
    loading.value = true;

    RequestAPI.getUid(values)
      .then((uid) => Promise.all([RequestAPI.search(uid), addFiles(uid)]))
      .then(([data]) => {
        items.value = data.items.map(mapItems);
        setSearchToken(data.requestId);
      })
      .catch(requestError)
      .finally(() => {
        loading.value = false;
      });
  }

  function addFiles(uid: string) {
    return Promise.all(
      files.value.map((file) => {
        const data = new FormData();
        data.append('file', file);
        return RequestAPI.addFiles(uid, data);
      })
    ).then(() => {
      selectedFiles.value.push(...files.value);
      files.value.splice(0);
    });
  }

  function get() {
    if (!getSearchToken()) return;

    loading.value = true;
    Promise.all([
      RequestAPI.getByUid(getSearchToken()),
      RequestAPI.getFiles(getSearchToken()),
    ])
      .then(([_form, files]) => {
        form.value = _form;
        selectedFiles.value.push(...files);
        return RequestAPI.search(getSearchToken());
      })
      .then((data) => {
        items.value = data.items.map(mapItems);
        min.value = data.orderMinimalAreaPercent;
        max.value = data.orderMaximalAreaOverheadPercent;
        setSearchToken(data.requestId);
      })
      .catch(requestError)
      .finally(() => {
        loading.value = false;
      });
  }

  function addOrder() {
    orderLoading.value = true;
    const offer = selectedItems.value[0];
    return RequestAPI.addOffer(getSearchToken(), {
      partnerId: offer.partner.uid,
      crew: offer.crews.map<string>((crew) => crew.uid),
      area: offer.area,
      days: offer.days,
      startAt: offer.startAt,
      locationId: offer.location?.uid || '',
    } as IOffer)
      .then(() => {
        success('Заявка створена, очікуйте відповіді від виконавця.');
        router.push(`/offer/${getSearchToken()}`);
        selectedItems.value = [];
        clearSearchToken();
      })
      .catch(requestError)
      .finally(() => {
        orderLoading.value = false;
      });
  }

  return {
    loading,
    orderLoading,
    items,
    form,
    selectedFiles,
    files,
    parsedSelectedItems,
    selectedItems,
    selectedSqaure,
    selectedPrice,
    min,
    max,
    search,
    get,
    addOrder,
  };
}
