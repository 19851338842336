import i18n from '@/plugins/i18n';

export function formatNumber(number: any): string {
  return parseFloat(number)
    ? parseFloat(number)
        .toLocaleString('en', { style: 'decimal', minimumFractionDigits: 0 })
        .replaceAll(',', ' ')
    : '';
}

export function formatFloat(number: any, decimals = 2): string | number {
  return parseFloat(number)
    ? parseFloat(parseFloat(number).toFixed(decimals))
    : '';
}

export function formatPrice(n: number | string): string {
  if (!n && !['number', 'string'].includes(typeof n)) return '';
  return `${formatFloat(+n < 0 ? 0 : +n) || 0} ${i18n.global.t('common.uah')}`;
}

export function formatSquare(n: number | string): string {
  if (!n && !['number', 'string'].includes(typeof n)) return '';
  return `${formatNumber(+n) || 0} ${i18n.global.t('common.hectare')}`;
}

export function formatDistance(n: number | string): string {
  if (!n && !['number', 'string'].includes(typeof n)) return '';
  return `${+formatFloat(+n / 1000, 3) || 0} ${i18n.global.t('common.km')}`;
}

export function adjustHexColor(color: string, amount: number): string {
  return (
    '#' +
    color
      .replace(/^#/, '')
      .replace(/../g, (color: string) =>
        (
          '0' +
          Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)
        ).substr(-2)
      )
  );
}

export function parseCount(num: number): number {
  if (10 < num % 100 && num % 100 < 20) {
    return 2;
  }
  if (num % 10 === 1) {
    return 0;
  }
  if (1 < num % 10 && num % 10 < 5) {
    return 1;
  } else {
    return 2;
  }
  // const list = [
  //   [0, 2],
  //   [1, 0],
  //   [2, 1],
  //   [3, 1],
  //   [4, 1],
  //   [5, 2],
  //   [6, 2],
  //   [7, 2],
  //   [8, 2],
  //   [9, 2],
  //   [10, 2],
  //   [11, 2],
  //   [12, 2],
  //   [13, 2],
  //   [14, 2],
  //   [15, 2],
  //   [16, 2],
  //   [17, 2],
  //   [18, 2],
  //   [19, 2],
  //   [20, 2],
  //   [21, 0],
  //   [22, 1],
  //   [37, 2],
  //   [51, 0],
  //   [62, 1],
  //   [64, 1],
  //   [79, 2],
  //   [100, 2],
  //   [101, 0],
  //   [111, 2],
  //   [187, 2],
  //   [211, 2],
  //   [252, 1],
  //   [777, 2],
  //   [1025, 2],
  // ];
  // list.forEach((element) => {
  //   const result = parseCount(element[0]);
  //   const isOk = result === element[1];
  //   console.log(element[0], element[1], result, !isOk && '!!!!!!!!!!!!');
  // });
}

export function dayDifference(from: any, to: any) {
  let days = 0;
  for (let f = from; f <= to; ) {
    days++;
    const fromCopy = new Date(f);
    fromCopy.setDate(fromCopy.getDate() + 1);
    f = dateFormat(fromCopy);
  }

  return days;
}

export function dateFormat(date: Date): string {
  const year = `${date.getFullYear()}`;
  const month = `${date.getMonth() + 1}`.padStart(2, '0');
  const day = `${date.getDate()}`.padStart(2, '0');
  return `${year}-${month}-${day}`;
}
